import React from 'react'
import { graphql } from 'gatsby'
import { Container, Flex, Box, Badge, Link } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import LogosStrip from '@solid-ui-components/LogosStrip'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import ModalPortfolio from '@solid-ui-blocks/Modal/Block03'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import Features from '@solid-ui-blocks/Features/Block05'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import WhyChooseUs from '@solid-ui-blocks/Features/Block01'
import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block06'
import FeatureThree from '@solid-ui-blocks/FeaturesWithPhoto/Block03'
import FeatureThreeMore from '@solid-ui-blocks/Features/Block06'
import MainServices from '@solid-ui-blocks/MainServices/Block01'
import Services from '@solid-ui-blocks/Services/Block01'
import Faq from '@solid-ui-blocks/Faq/Block01'
import Footer from '@solid-ui-blocks/Footer/Block02'
import Contact from '@solid-ui-blocks/CallToAction/Block02'
import Testimonials from '@solid-ui-blocks/Testimonials/Block01'
import useSiteMetadata from '@blocks-helpers/useSiteMetadata'
import { CookieNotice } from 'gatsby-cookie-notice'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'
import ContentMap from '@solid-ui-components/ContentMap'
import ContentOne from '@solid-ui-blocks/Content/Block01'
import HeroBar from '@solid-ui-blocks/HeroBar/Block01'
import HeroSlider, { Overlay, Slide, MenuNav } from 'hero-slider'
import './styles.css'

const bogliasco =
  '../../../assets/flexiblocks/services/motor-vehicle-accidents.jpeg'
const countyClare = 'https://i.imgur.com/idjXzVQ.jpg'
const craterRock = 'https://i.imgur.com/8DYumaY.jpg'
const giauPass = 'https://i.imgur.com/8IuucQZ.jpg'

const IndexPage = props => {
  const {
    allBlockContent,
    googlePlacesPlace: {
      rating,
      user_ratings_total,
      childrenGooglePlacesReview
    }
  } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  const siteMetadata = useSiteMetadata()
  const { node, location, slug } = props.pageContext

  // console.log({ siteMetadata })
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'http://schema.org/LocalBusiness',
    name: siteMetadata.title,
    description: siteMetadata.description,
    url: siteMetadata.siteUrl,
    logo: siteMetadata.logo,
    sameAs: siteMetadata.social,
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: siteMetadata.phone,
      contactType: 'Service'
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: rating,
      reviewCount: user_ratings_total
    }
  }
  return (
    <Layout theme={theme} {...props}>
      <Seo
        title='Top Primary Care & Medical Center'
        uri={props.uri}
        indexed={true}
        keywords={[
          'find a doctor arlington heights',
          'local doctors arlington heights',
          'doctors near me arlington heights',
          'dr near me arlington heights',
          'doctor unitedhealth insurance arlington heights',
          'doctor wellpoint insurance arlington heights',
          'doctor kaiser foundation insurance',
          'doctor humana insurance',
          'doctor aetna insurance',
          'doctor hcsc insurance',
          'doctor cigna insurance',
          'find a physician arlington heights',
          'physicians near me arlington heights',
          'find a doctor near me arlington heights',
          'medical doctor near me arlington heights',
          'doctors in my area arlington heights',
          'find a local doctor arlington heights'
        ]}
        schemaMarkup={schema}
      />

      {/* Modals */}
      {/* <ModalWithTabs content={content['authentication']} reverse /> */}
      <ModalWithTabs content={content['contact']} />
      {/* <ModalPortfolio content={content['physicians']} reverse /> */}
      {/* Blocks */}
      <Header content={content['header']} />

      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} form={false} reverse />
      </Container>

      {/* <Container variant='full' sx={styles.insuranceContainer}>
        <LogosStrip content={content['insurance']} />
      </Container> */}
      {/* <Container variant='full' sx={styles.insuranceContainer}>
        <LogosStrip content={content['insurance']} />
      </Container> */}
      {/* <Divider space='4' /> */}
      <Divider space={-5} />
      <Container variant='wide'>
        <Divider space={-2} />
        <MainServices content={content['main-services']} reverse />
        <Divider space='4' />
        {/* <Hero content={content['main-content']} reverse /> */}
        <Divider space='4' />
        {/* <HeroBar content={content['hero-bar']} /> */}
      </Container>
      {/* <Divider space='4' />
      <Container variant='wide'>
        <ContentOne content={content['content-one']} />
      </Container> */}

      {/* <Divider space='4' />
      <Container sx={styles.featureOneContainer}>
        <MainServices content={content['main-services']} reverse />
      </Container> */}
      {/*
      <Divider space='4' />
      <Container sx={styles.featureOneContainer}>
        <FeatureOne content={content['feature-one']} reverse />
      </Container>
      <Divider space='4' />
      <Divider space='4' />
      <Container sx={styles.featureOneContainer}>
        <FeatureOne content={content['feature-two']} />
      </Container>
      <Divider space='4' />
      <Divider space='4' />
      <Container sx={styles.featureOneContainer}>
        <FeatureOne content={content['feature-three']} reverse />
      </Container> */}
      {/* <Divider space='4' /> */}
      {/* <Divider space='4' />
      <Container sx={styles.featureOneContainer}>
        <FeatureOne content={content['feature-four']} />
      </Container> */}
      {/* <Divider space='4' /> */}
      {/* <Divider space='4' />
      <Container sx={styles.featureOneContainer}>
        <FeatureOne content={content['feature-five']} reverse />
      </Container>
      <Divider space='4' /> */}
      {/* <Divider space='4' /> */}
      <Services content={content['services']} id='services' />
      <Divider space='4' />
      {/* <Divider space='4' /> */}
      <Container variant='full'>
        <Contact content={content['pricing-and-insurance']} />
      </Container>
      {/* <Divider space='4' /> */}
      <Divider space='4' />
      <Divider space='4' />
      <Testimonials content={content['testimonials']} />
      {/* <Divider space='4' /> */}
      <Divider space='4' />
      <Container variant='full'>
        <Faq content={content['faq']} />
      </Container>
      {/* <Divider space='4' /> */}
      <Divider space='4' />
      <Box>
        <ContentMap
          content={{
            lat: 42.0985394,
            lng: -87.9600665,
            zoom: 13
          }}
          height='600'
        />
      </Box>
      {/* <Container variant='wide' sx={styles.whyChooseUsContainer}>
        <WhyChooseUs content={content['why-choose-us']} />
      </Container>
      <Divider space='4' />
      <Divider space='4' /> */}
      {/* <Container variant='wide' sx={styles.featureTwoContainer}>
        <FeatureTwo content={content['feature-two']} reverse />
      </Container>
      <Divider space='4' />
      <Divider space='4' /> */}
      {/* <Container variant='full' sx={styles.featureThreeContainer}>
        <FeatureThree content={content['feature-three']} />
        <Divider space='4' />
        <FeatureThreeMore content={content['feature-three-more']} />
      </Container> */}
      {/* <CookieNotice
        personalizeButtonClasses={'my-btn-classes'}
        // personalizeButtonText={'I want to choose my cookies !'}
        cookies={[
          {
            name: 'necessary',
            editable: false,
            default: true,
            title: 'Essentiel',
            text: 'Essential cookies are necessary for the proper functioning of the site. The site cannot function properly without them.'
          },
          {
            name: 'gatsby-gdpr-google-analytics',
            editable: false,
            default: true,
            title: 'Google Analytics',
            text: 'Google Analytics is a statistical tool of Google allowing to measure the audience of the website.'
          }
        ]}
      >
        <h4>This websites uses cookies.</h4>
        <p>
          We use cookies to make the site work better, but also to see how you
          interact with it. how you interact with it. We will only use cookies
          if you allow us to do so by clicking by clicking on "Accept Cookies".
          You can also choose which cookie you want to allow.
        </p>
      </CookieNotice> */}
      <Footer content={content['footer2']} />
    </Layout>
  )
}

export const query = graphql`
  query homepageRandBlockContent {
    allBlockContent(filter: { page: { in: ["site/index", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
    googlePlacesPlace {
      name
      rating
      childrenGooglePlacesReview {
        author_name
        text
        rating
        profile_photo_url
      }
      user_ratings_total
    }
  }
`
export default IndexPage
