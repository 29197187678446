import React from 'react'
import { Container, Flex, Box } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import Counter from '@solid-ui-components/Counter'
import ContentContainer from '@solid-ui-components/ContentContainer'
import ContentText from '@solid-ui-components/ContentText'
import ContentButtons from '@solid-ui-components/ContentButtons'
import Icon from '@solid-ui-components/ContentIcon'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const HeroBarBlock01 = ({
  content: { text, container, collection, buttons }
}) => (
  <Container sx={{ textAlign: `center` }}>
    <ContentContainer content={container} variant='cards.paper-md'>
      <ContentText content={text} />
      {text && collection && <Divider space={3} />}
      {collection && (
        <>
          <Flex
            sx={{
              flexWrap: `wrap`,
              alignItems: `flex-start`,
              alignContent: `center`,
              justifyContent: `center`,
              flexDirection: [`column`, `row`],
              justifyContent: `space-between`,
              alignItems: `center`,
              textAlign: [`center`, `left`],
              wordWrap: 'break-word',
              m: 3
            }}
          >
            {collection?.map(({ text }, index) => (
              <Box
                key={`item-${index}`}
                sx={{
                  flex: `1`,
                  minWidth: 100,
                  textAlign: `center`,
                  p: 1
                }}
              >
                <Reveal effect='fadeInGrow'>
                  <ContentText content={text?.[0]} mb='0'>
                    <Box sx={{ display: `inline-block` }}>
                      {/* {console.log(text?.[1]?.icon)} */}
                      {/* <Flex sx={{ alignItems: `center` }} p='3'>
                      <Icon content={icon} size='xs' mr='3' p='2' round />
                      <ContentText content={text?.[0]} mb='0' pr='5' />
                    </Flex>
                     */}
                      {/* <Icon content={text?.[0]?.icon} size='xs' mr='1' /> */}
                    </Box>
                    {/* <Counter to={parseInt(text?.[0]?.text)} /> */}
                    {text?.[0]?.text}
                  </ContentText>
                  <ContentText
                    type={text?.[1].type}
                    content={text?.[1]}
                    sx={{ fontWeight: `body` }}
                    mb='0'
                  />
                </Reveal>
              </Box>
            ))}
          </Flex>
        </>
      )}
      {buttons && (
        <>
          <Divider />
          <ContentButtons content={buttons} />
        </>
      )}
    </ContentContainer>
  </Container>
)

export default WithDefaultContent(HeroBarBlock01)
